import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextWorkFrame = makeShortcode("TextWorkFrame");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`華雪`}</h1>
    <p>{`リ・ビンユアンのパフォーマンス映像を見ていた華雪さんは、小さい板を濁流に向かって掲げ続けたり、橋の上での側転を何年も続けたりと、シンプルな同じ行為を繰り返す姿に共感しました。漢数字の「一」を、約２メートル × １メートルの大きな和紙に、繰り返し書くという創作を行いました。「一」を書いた上にまた「一」と書く、ということを繰り返していくと、紙の表面が毛羽立ち、最後は岩の肌のようになったということです。`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1280px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFk+4uJpvB//8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDBBMA/9oACAEBAAEFAtCHisgEebqYnrGgGaQkvv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAQACAQUBAAAAAAAAAAAAAAEAAiEDERIiMVH/2gAIAQEABj8CwKyrku+TLpTkdVltSpvb6zscl9Wf/8QAHBAAAgICAwAAAAAAAAAAAAAAAREAITFBUWGR/9oACAEBAAE/IWzkRC40LJN8RFGPZUrP2qaIGTsmIMV4PM//2gAMAwEAAgADAAAAEGQv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxCLj//EAB4QAQEAAQQDAQAAAAAAAAAAAAERACExQVFhcbHh/9oACAEBAAE/ECwqFGocz8znLJFGxN8ykILruesMNei213ri0XlEllXrwTD7Z0FT8OjP/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/be87afb871c18e7c48e5f8ec4984e13b/ec4f2/kasetsu.webp 320w", "/static/be87afb871c18e7c48e5f8ec4984e13b/74ed4/kasetsu.webp 640w", "/static/be87afb871c18e7c48e5f8ec4984e13b/265b4/kasetsu.webp 1280w", "/static/be87afb871c18e7c48e5f8ec4984e13b/5c42a/kasetsu.webp 1600w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/webp"
        }}></source>{`
        `}<source parentName="picture" {...{
          "srcSet": ["/static/be87afb871c18e7c48e5f8ec4984e13b/9328e/kasetsu.jpg 320w", "/static/be87afb871c18e7c48e5f8ec4984e13b/f24a5/kasetsu.jpg 640w", "/static/be87afb871c18e7c48e5f8ec4984e13b/36316/kasetsu.jpg 1280w", "/static/be87afb871c18e7c48e5f8ec4984e13b/4133c/kasetsu.jpg 1600w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "type": "image/jpeg"
        }}></source>{`
        `}<img parentName="picture" {...{
          "className": "gatsby-resp-image-image",
          "src": "/static/be87afb871c18e7c48e5f8ec4984e13b/36316/kasetsu.jpg",
          "alt": "kasetsu",
          "title": "kasetsu",
          "loading": "lazy",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          }
        }}></img>{`
      `}</picture>{`
    `}</span>
    <TextWorkFrame type="kasetsu" mdxType="TextWorkFrame" />
    <Caption before={`作品`} medium={`書、テキスト　2020`} mdxType="Caption">
  《線を引く—「一」を書く》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/binyuan-li_drawingboard`} mdxType="LinkButton">
  作品: リ・ビンユアン
    </LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      